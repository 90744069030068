import Vue from 'vue'
import VueRouter from 'vue-router'
import checkAuth from '@/middleware/auth'


Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/',
    //  redirect: 'dashboard',
    redirect: '/auth/login'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  // Part for case management
  {
    path: '/oath-cases',
    name: 'oath-cases',
    component: () => import('@/views/pages/cases/OathCases.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/oath-cases/add',
    name: 'oath-cases-add',
    component: () => import('@/views/pages/cases/AddOathCase.vue'),
    meta: {
      middleware: checkAuth
    }

  },
  {
    path: '/civil-cases',
    name: 'civil-cases',
    component: () => import('@/views/pages/cases/CivilCases.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/police-cases',
    name: 'police-cases',
    component: () => import('@/views/pages/cases/police/CasePoliceList.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/police-cases/add',
    name: 'police-cases-add',
    component: () => import('@/views/pages/cases/police/AddCasePolice.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/police-cases/edit/:id',
    name: 'police-cases-edit',
    component: () => import('@/views/pages/cases/police/AddCasePolice.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/police-cases/transfer/:id',
    name: 'police-cases-transfer',
    component: () => import('@/views/pages/cases/police/AddCaseTransfer.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/criminal-cases',
    name: 'criminal-cases',
    component: () => import('@/views/pages/cases/CriminalCases.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/criminal-cases/add',
    name: 'criminal-cases-add',
    component: () => import('@/views/pages/cases/AddCriminalCase.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/criminal-cases/edit/:id',
    name: 'criminal-cases-edit',
    component: () => import('@/views/pages/cases/AddCriminalCase.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/criminal-cases/progress/edit/:id',
    name: 'criminal-cases-progress',
    component: () => import('@/views/pages/cases/CriminalCaseProgress.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/defendant-cases',
    name: 'defendant-cases',
    component: () => import('@/views/pages/cases/CriminalCases.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/pages/Logout")
  },
  //Routers for system
  //for admin
  {
    path: '/staff',
    name: 'staff',
    component: () => import('@/views/pages/administration/Staff.vue'),
    meta: {
      middleware: checkAuth
    }
  },
  {
    path: '/staff/add',
    name: 'add-staff',
    component: () => import('@/views/pages/administration/AddStaff.vue'),
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/pages/administration/UserList.vue'),
  },
  {
    path: '/system-setting/positions',
    name: 'system-setting-positions',
    component: () => import('@/views/pages/system-setting/PositionList.vue'),
  },
  {
    path: '/system-setting/medals',
    name: 'system-setting-medals',
    component: () => import('@/views/pages/system-setting/MedalList.vue'),
  },
  {
    path: '/system-setting/dashboard',
    name: 'system-setting-dashboard',
    component: () => import('@/views/pages/system-setting/SystemDashboard.vue'),
  },
  {
    path: '/system-setting/case-letter-types',
    name: 'system-setting-case-letter-types',
    component: () => import('@/views/pages/system-setting/CaseLetterType.vue'),
  },
  {
    path: '/system-setting/case-task-types',
    name: 'system-setting-case-task-types',
    component: () => import('@/views/pages/system-setting/CaseTaskType.vue'),
  },
  {
    path: '/system-setting/evidence-types',
    name: 'system-setting-evidence-types',
    component: () => import('@/views/pages/system-setting/EvidenceType.vue'),
  },
  {
    path: '/system-setting/crime-types',
    name: 'system-setting-crime-types',
    component: () => import('@/views/pages/system-setting/CrimeType.vue'),
  },
  {
    path: '/system-setting/decision-types',
    name: 'system-setting-decision-types',
    component: () => import('@/views/pages/system-setting/CaseDecisionType.vue'),
  },
  // for report
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/pages/reports/Report.vue'),
  },

  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {

    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    if (localStorage.getItem('user') != null) {
      var token = JSON.parse(localStorage.getItem('user')).accessToken
      const jwtPayload = parseJwt(token)

      if (jwtPayload.exp < Date.now() / 1000) {
        // token expired
        localStorage.removeItem("user")
        localStorage.clear()
        next('/auth/login')
      }
    }
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next()
})

export default router
